import { getConfig } from 'config/config';

const { config } = getConfig();
export type langTypes = 'GER' | 'ENG' | 'FRA' | 'HNG' | 'ITA' | 'JAP' | 'SP' | 'KOR' | 'CIN' | 'POL' | 'RUS';

export const AppName = config.APP_FULL_NAME || 'Connact App';
export const PostsName = config.POSTS_NAME || 'Neuigkeiten';

export const passwordDigits = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'atlas':
    case 'connact':
      return {
        GER: '12',
        ENG: '12',
        FRA: '12',
        HNG: '12',
        ITA: '12',
        JAP: '12',
        SP: '12',
        KOR: '12',
        CIN: '12',
        POL: '12',
        RUS: '12',
      }[lang];
    default:
      return {
        GER: 'acht',
        ENG: 'eight',
        FRA: 'huit',
        HNG: 'nyolc',
        ITA: 'otto',
        JAP: 'エイト',
        SP: 'ocho',
        KOR: '여덟',
        CIN: '八',
        POL: 'osiem',
        RUS: 'Восемь',
      }[lang];
  }
};

export const acceptEventName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'bvs':
    case 'landfrauen':
    case 'yoga':
      return {
        GER: { accept: 'Interessiert', cancel: 'Nicht interessiert' },
        ENG: { accept: 'Interested', cancel: 'Not interested' },
        ITA: { accept: 'Interessato', cancel: 'Non interessato' },
        FRA: { accept: 'Intéressé', cancel: 'Pas intéressé' },
        HNG: { accept: 'Érdeklődik', cancel: 'Nem érdekel' },
        JAP: { accept: '興味あり', cancel: '興味なし' },
        SP: { accept: 'Interesados', cancel: 'No me interesa' },
        KOR: { accept: '관심', cancel: '관심 없음' },
        CIN: { accept: '感兴趣', cancel: '不感兴趣' },
        POL: { accept: 'Zainteresowany', cancel: 'Nie jestem zainteresowany' },
        RUS: { accept: 'Интересует', cancel: 'Неинтересно' },
      }[lang];
    default:
      return {
        GER: { accept: 'Zusagen', cancel: 'Absagen' },
        ENG: { accept: 'Accept', cancel: 'Cancel' },
        ITA: { accept: 'Impegni', cancel: 'Cancellazioni' },
        FRA: { accept: 'Engagements', cancel: 'Annulations' },
        HNG: { accept: 'Kötelezettségvállalások', cancel: 'Lemondások' },
        JAP: { accept: 'コミットメント', cancel: 'アブサゲン' },
        SP: { accept: 'Compromisos', cancel: 'Anulaciones' },
        KOR: { accept: '약속', cancel: '취소' },
        CIN: { accept: '承诺', cancel: '取消预订' },
        POL: { accept: 'Zobowiązania', cancel: 'Anulowanie' },
        RUS: { accept: 'Принять', cancel: 'Отмена' },
      }[lang];
  }
};

export const SignUpCorpsName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    default:
      return {
        GER: 'Mitgliedschaft',
        ENG: 'Membership',
        FRA: 'Adhésion',
        HNG: 'Tagság',
        ITA: 'Soci',
        JAP: 'メンバーシップ',
        SP: 'Afiliación',
        KOR: '멤버십',
        CIN: '会员资格',
        POL: 'Członkostwo',
        RUS: 'Членство',
      }[lang];
  }
};

export const KreiseName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'meinp3':
    case 'werteunion':
      return {
        GER: { multi: 'Gruppen', single: 'Gruppe', singleArticle: 'diese Gruppe' },
        ENG: { multi: 'Groups', single: 'Group' },
        FRA: { multi: 'Groupes', single: 'Groupe' },
        HNG: { multi: 'Csoportok', single: 'Csoport' },
        ITA: { multi: 'Gruppi', single: 'Gruppo' },
        JAP: { multi: 'グループ', single: '団体', singleArticle: 'このグループ' },
        SP: { multi: 'Grupos', single: 'Grupo', singleArticle: 'este grupo' },
        KOR: { multi: '그룹', single: '그룹', singleArticle: '이 그룹' },
        CIN: { multi: '组别', single: '组别', singleArticle: '该组' },
        POL: { multi: 'Grupy', single: 'Grupa', singleArticle: 'ta grupa' },
        RUS: { multi: 'Группы', single: 'Группа', singleArticle: 'эта группа' },
      }[lang];
    case 'aps':
      return {
        GER: { multi: 'Gruppenchats und AGs', single: 'Gruppenchat und AG', singleArticle: 'diesen Gruppenchat' },
        ENG: { multi: 'Groups', single: 'Group' },
        FRA: { multi: 'Groupes', single: 'Groupe' },
        HNG: { multi: 'Csoportok', single: 'Csoport' },
        ITA: { multi: 'Gruppi', single: 'Gruppo' },
        JAP: { multi: 'グループチャットとAG', single: 'グループチャットとAG', singleArticle: 'このグループチャット' },
        SP: { multi: 'Chats de grupo y AG', single: 'Chat en grupo y AG', singleArticle: 'este chat de grupo' },
        KOR: { multi: '그룹 채팅 및 AG', single: '그룹 채팅 및 AG', singleArticle: '이 그룹 채팅' },
        CIN: { multi: '群聊和 AG', single: '群聊和 AG', singleArticle: '这个群聊' },
        POL: { multi: 'Czaty grupowe i AG', single: 'Czat grupowy i AG', singleArticle: 'ten czat grupowy' },
        RUS: { multi: 'Групповые чаты и AG', single: 'Групповой чат и AG', singleArticle: 'этот групповой чат' },
      }[lang];
    default:
      return {
        GER: { multi: 'Gruppenchats', single: 'Gruppenchat', singleArticle: 'diesen Gruppenchat' },
        ENG: { multi: 'Group chats', single: 'Group chat' },
        FRA: { multi: 'Chats de groupe', single: 'Chat de groupe' },
        HNG: { multi: 'Csoportos beszélgetések', single: 'Csoportos csevegés' },
        ITA: { multi: 'Chat di gruppo', single: 'Chat di gruppo' },
        JAP: { multi: 'グループチャット', single: 'グループチャット', singleArticle: 'このグループチャット' },
        SP: { multi: 'Chats de grupo', single: 'Chat en grupo', singleArticle: 'este chat de grupo' },
        KOR: { multi: '그룹 채팅', single: '그룹 채팅', singleArticle: '이 그룹 채팅' },
        CIN: { multi: '群组聊天', single: '群组聊天', singleArticle: '这个群聊' },
        POL: { multi: 'Czaty grupowe', single: 'Czat grupowy', singleArticle: 'ten czat grupowy' },
        RUS: { multi: 'Групповые чаты', single: 'Групповой чат', singleArticle: 'этот групповой чат' },
      }[lang];
  }
};

export const StudyName = (lang: langTypes) => {
  let defaultNames = {
    GER: {
      hochsule: 'Ausbildung-/Hochschule',
      studiengang: 'Ausbildung-/Studiengang',
      abshchluss: 'Abschluss',
      short: 'Ausbildung',
      university: 'Hochschule',
    },
    ENG: {
      hochsule: 'Education-/Higher education',
      studiengang: 'Education-/Study program',
      abshchluss: 'Conclusion',
      short: 'Education',
      university: 'Higher education',
    },
    FRA: {
      hochsule: 'Formation-/École supérieure',
      studiengang: "Formation-/Filière d'études",
      abshchluss: 'Conclusion',
      short: 'Formation',
      university: 'École supérieure',
    },
    HNG: {
      hochsule: 'Képzés-/Gimnázium',
      studiengang: 'Képzés-/Tanulmányi program',
      abshchluss: 'Zárás',
      short: 'Képzés',
      university: 'Gimnázium',
    },
    ITA: {
      hochsule: 'Formazione-/Scuola superiore',
      studiengang: 'Formazione-/Programma di studio',
      abshchluss: 'Chiusura',
      short: 'Formazione',
      university: 'Scuola superiore',
    },
    JAP: {
      hochsule: 'トレーニング/大学',
      studiengang: '研修/学習プログラム',
      abshchluss: '結論',
      short: '教育',
      university: '大学',
    },
    SP: {
      hochsule: 'Formación/universidad',
      studiengang: 'Programa de formación/estudio',
      abshchluss: 'Conclusión',
      short: 'Educación',
      university: 'Universidad',
    },
    KOR: {
      hochsule: '교육/대학',
      studiengang: '교육/학습 프로그램',
      abshchluss: '결론',
      short: '교육',
      university: '대학',
    },
    CIN: {
      hochsule: '培训/大学',
      studiengang: '培训/学习计划',
      abshchluss: '结论',
      short: '教育',
      university: '大学',
    },
    POL: {
      hochsule: 'Szkolenie/uniwersytet',
      studiengang: 'Program szkoleniowy/studyjny',
      abshchluss: 'Wnioski',
      short: 'Edukacja',
      university: 'Uniwersytet',
    },
    RUS: {
      hochsule: 'Обучение/университет',
      studiengang: 'Программа подготовки/обучения',
      abshchluss: 'Заключение',
      short: 'Образование',
      university: 'Университет',
    },
  };
  switch (config.APP_NAME) {
    case 'meinp3':
      defaultNames.GER.studiengang = 'Aus-/Weiterbildung';
      defaultNames.ENG.studiengang = 'Education-/Training';
      defaultNames.FRA.studiengang = 'Formation initiale-/Continue';
      defaultNames.HNG.studiengang = 'Oktatás/-Képzés';
      defaultNames.ITA.studiengang = 'Istruzione-/Formazione';
      defaultNames.JAP.studiengang = 'トレーニング/進学';
      defaultNames.SP.studiengang = 'Formación';
      defaultNames.KOR.studiengang = '교육/추가 교육';
      defaultNames.CIN.studiengang = '培训/继续教育';
      defaultNames.POL.studiengang = 'Szkolenie/dalsza edukacja';
      defaultNames.RUS.studiengang = 'Обучение/повышение квалификации';
      return defaultNames[lang];
    case 'wilkar':
      defaultNames.GER.hochsule = 'Fremdsprachen';
      defaultNames.GER.university = 'Fremdsprachen';
      defaultNames.GER.studiengang = 'Aus-/Weiterbildung';

      defaultNames.ENG.hochsule = 'Foreign languages';
      defaultNames.ENG.university = 'Foreign languages';
      defaultNames.ENG.studiengang = 'Training/further training';

      defaultNames.ITA.hochsule = 'Lingue straniere';
      defaultNames.ITA.university = 'Lingue straniere';
      defaultNames.ITA.studiengang = 'Formazione/istruzione continua';

      defaultNames.FRA.hochsule = 'Langues étrangères';
      defaultNames.FRA.university = 'Langues étrangères';
      defaultNames.FRA.studiengang = 'Formation initiale/continue';

      defaultNames.HNG.hochsule = 'Idegen nyelvek';
      defaultNames.HNG.university = 'Idegen nyelvek';
      defaultNames.HNG.studiengang = 'Képzés/továbbképzés';

      defaultNames.JAP.hochsule = '外国語';
      defaultNames.JAP.university = '外国語';
      defaultNames.JAP.studiengang = 'トレーニング/進学';

      defaultNames.SP.hochsule = 'Idiomas extranjeros';
      defaultNames.SP.university = 'Idiomas extranjeros';
      defaultNames.SP.studiengang = 'Formación';

      defaultNames.KOR.hochsule = '외국어';
      defaultNames.KOR.university = '외국어';
      defaultNames.KOR.studiengang = '교육/추가 교육';

      defaultNames.CIN.hochsule = '外语';
      defaultNames.CIN.university = '外语';
      defaultNames.CIN.studiengang = '培训/继续教育';

      defaultNames.POL.hochsule = 'Języki obce';
      defaultNames.POL.university = 'Języki obce';
      defaultNames.POL.studiengang = 'Szkolenie/dalsza edukacja';

      defaultNames.RUS.hochsule = 'Иностранные языки';
      defaultNames.RUS.university = 'Иностранные языки';
      defaultNames.RUS.studiengang = 'Обучение/повышение квалификации';
      return defaultNames[lang];
    default:
      return defaultNames[lang];
  }
};

export const FunctionName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'meinp3':
      return {
        GER: 'Bereich',
        ENG: 'Area',
        FRA: 'Domaine',
        HNG: 'Terület',
        ITA: 'Area',
        JAP: 'レンジ',
        SP: 'Gama',
        KOR: '범위',
        CIN: '范围',
        POL: 'Zasięg',
        RUS: 'Диапазон',
      }[lang];
    case 'bvs':
      return {
        GER: 'Bestellungstenor',
        ENG: 'Scope',
        FRA: 'Scope',
        HNG: 'Scope',
        ITA: 'Scope',
        JAP: 'オーダーテナー',
        SP: 'Pedir tenor',
        KOR: '테너 주문',
        CIN: '订购男高音',
        POL: 'Zamów tenor',
        RUS: 'Заказать тенор',
      }[lang];
    default:
      return {
        GER: 'Funktion',
        ENG: 'Function',
        FRA: 'Fonction',
        HNG: 'Funkció',
        ITA: 'Funzione',
        JAP: '機能',
        SP: 'Función',
        KOR: '기능',
        CIN: '功能',
        POL: 'Funkcja',
        RUS: 'Функция',
      }[lang];
  }
};

export const DocumentsName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'meinp3':
      return {
        GER: 'Downloads',
        ENG: 'Downloads',
        FRA: 'Téléchargements',
        HNG: 'Letöltések',
        ITA: 'Scaricamento',
        JAP: 'ダウンロード',
        SP: 'Descargas',
        KOR: '다운로드',
        CIN: '下载',
        POL: 'Pliki do pobrania',
        RUS: 'Загрузки',
      }[lang];

    case 'aps':
      return {
        GER: 'Dokumente & Projekte',
        ENG: 'Documents & Projects',
        FRA: 'Documents et projets',
        HNG: 'Dokumentumok és projektek',
        ITA: 'Documenti e progetti',
        JAP: 'ドキュメント＆プロジェクト',
        SP: 'Documentos y proyectos',
        KOR: '문서 및 프로젝트',
        CIN: '文件与项目',
        POL: 'Dokumenty i projekty',
        RUS: 'Документы и проекты',
      }[lang];
    default:
      return {
        GER: 'Dokumente',
        ENG: 'Documents',
        FRA: 'Documents',
        HNG: 'Dokumentumok',
        ITA: 'Documenti',
        JAP: '書類',
        SP: 'Documentos',
        KOR: '문서',
        CIN: '文件',
        POL: 'Dokumenty',
        RUS: 'Документы',
      }[lang];
  }
};

export const CorpsEditDate = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'meinp3':
      return {
        GER: 'Bei P3 seit',
        ENG: 'At P3 since',
        FRA: 'À P3 puisque',
        HNG: 'A P3-nál',
        ITA: 'In P3 da quando',
        JAP: 'P3以来',
        SP: 'En P3 desde',
        KOR: '이후 P3에서',
        CIN: '在 P3，因为',
        POL: 'W P3 od',
        RUS: 'В P3 с',
      }[lang];
    default:
      return {
        GER: 'Datum des Beitritts',
        ENG: 'Date of joining',
        FRA: "Date d'adhésion",
        HNG: 'A csatlakozás időpontja',
        ITA: 'Data di adesione',
        JAP: '加盟日',
        SP: 'Fecha de adhesión',
        KOR: '가입 날짜',
        CIN: '加入日期',
        POL: 'Data przystąpienia',
        RUS: 'Дата вступления',
      }[lang];
  }
};

export const CalendarName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'meinp3':
      return {
        GER: 'Termine & Geburtstage',
        ENG: 'Dates & Birthdays',
        FRA: 'Dates & anniversaires',
        HNG: 'Dátumok és születésnapok',
        ITA: 'Date e compleanni',
        JAP: '日付と誕生日',
        SP: 'Fechas y cumpleaños',
        KOR: '날짜 및 생일',
        CIN: '日期和生日',
        POL: 'Daty i urodziny',
        RUS: 'Даты и дни рождения',
      }[lang];
    case 'aps':
      return {
        GER: 'Terminkalender',
        ENG: 'Appointment calendar',
        FRA: 'Agenda',
        HNG: 'Napló',
        ITA: 'Diario',
        JAP: '予約カレンダー',
        SP: 'Calendario de citas',
        KOR: '약속 캘린더',
        CIN: '预约日历',
        POL: 'Kalendarz spotkań',
        RUS: 'Календарь назначений',
      }[lang];
    default:
      return {
        GER: 'Veranstaltungen',
        ENG: 'Events',
        FRA: 'Événements',
        HNG: 'Események',
        ITA: 'Eventi',
        JAP: 'イベント',
        SP: 'Eventos',
        KOR: '이벤트',
        CIN: '活动',
        POL: 'Wydarzenia',
        RUS: 'Cобытия',
      }[lang];
  }
};

export const MessagesName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'aps':
      return {
        GER: 'Privatnachrichten',
        ENG: 'Private messages',
        ITA: 'Messaggi privati',
        FRA: 'Messages privé',
        HNG: 'Privát üzenetek',
        JAP: 'プライベートメッセージ',
        SP: 'Mensajes privados',
        KOR: '비공개 메시지',
        CIN: '私人信息',
        POL: 'Prywatne wiadomości',
        RUS: 'Личные сообщения',
      }[lang];
    default:
      return {
        GER: 'Nachrichten',
        ENG: 'Messages',
        ITA: 'Messaggi',
        FRA: 'Messages',
        HNG: 'Üzenetek',
        JAP: 'ニュース',
        SP: 'Noticias',
        KOR: '뉴스',
        CIN: '新闻',
        POL: 'Aktualności',
        RUS: 'Сообщения',
      }[lang];
  }
};

export const ProfileName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'aps':
      return {
        GER: 'Mein Profil',
        ENG: 'My profile',
        ITA: 'Il mio profilo',
        FRA: 'Mon Profil',
        HNG: 'Profilom',
        JAP: 'プロフィール',
        SP: 'Mi perfil',
        KOR: '내 프로필',
        CIN: '我的简介',
        POL: 'Mój profil',
        RUS: 'Мой профиль',
      }[lang];
    default:
      return {
        GER: 'Profil',
        ENG: 'Profile',
        ITA: 'Profilo',
        FRA: 'Profil',
        HNG: 'Profil',
        JAP: 'プロフィール',
        SP: 'Perfil',
        KOR: '프로필',
        CIN: '简介',
        POL: 'Profil',
        RUS: 'Профиль',
      }[lang];
  }
};

export const InfoContactsName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'aps':
      return {
        GER: 'Technischer Ansprechpartner',
        ENG: 'Technischer Ansprechpartner',
        ITA: 'Technischer Ansprechpartner',
        FRA: 'Technischer Ansprechpartner',
        HNG: 'Technischer Ansprechpartner',
        JAP: '技術担当者',
        SP: 'Persona de contacto técnico',
        KOR: '기술 담당자',
        CIN: '技术联系人',
        POL: 'Techniczna osoba kontaktowa',
        RUS: 'Контактное лицо по техническим вопросам',
      }[lang];
    default:
      return {
        GER: 'Kontakt',
        ENG: 'Contacts',
        ITA: 'Contacts',
        FRA: 'Contacts',
        HNG: 'Contacts',
        JAP: 'お問い合わせ',
        SP: 'Contacto',
        KOR: '문의하기',
        CIN: '联系我们',
        POL: 'Skontaktuj się z nami',
        RUS: 'Свяжитесь с нами',
      }[lang];
  }
};

export const CorpsName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'tlv':
      return {
        GER: { multi: 'Kreisverband', single: 'Kreisverband' },
        ENG: { multi: 'Kreisverband', single: 'Kreisverband' },
        FRA: { multi: 'Kreisverband', single: 'Kreisverband' },
        HNG: { multi: 'Kreisverband', single: 'Kreisverband' },
        ITA: { multi: 'Kreisverband', single: 'Kreisverband' },
        JAP: { multi: '地区協会', single: '地区協会' },
        SP: { multi: 'Asociación de distrito', single: 'Asociación de distrito' },
        KOR: { multi: '지구 협회', single: '지구 협회' },
        CIN: { multi: '地区协会', single: '地区协会' },
        POL: { multi: 'Stowarzyszenie okręgowe', single: 'Stowarzyszenie okręgowe' },
        RUS: { multi: 'Районная ассоциация', single: 'Районная ассоциация' },
      }[lang];
    case 'fib':
    case 'bvsbayern':
      return {
        GER: { multi: 'Vereine', single: 'Vereine' },
        ENG: { multi: 'Vereine', single: 'Vereine' },
        FRA: { multi: 'Vereine', single: 'Vereine' },
        HNG: { multi: 'Vereine', single: 'Vereine' },
        ITA: { multi: 'Vereine', single: 'Vereine' },
        JAP: { multi: 'クラブ', single: 'クラブ' },
        SP: { multi: 'Clubes', single: 'Clubes' },
        KOR: { multi: '클럽', single: '클럽' },
        CIN: { multi: '俱乐部', single: '俱乐部' },
        POL: { multi: 'Kluby', single: 'Kluby' },
        RUS: { multi: 'Клубы', single: 'Клубы' },
      }[lang];
    case 'bvs':
      return {
        GER: { multi: 'Verbände', single: 'Verband' },
        ENG: { multi: 'Associations', single: 'Association' },
        FRA: { multi: 'Association', single: 'Association' },
        HNG: { multi: 'Egyesület', single: 'Egyesület' },
        ITA: { multi: 'Associazione', single: 'Associazione' },
        JAP: { multi: '協会', single: '協会' },
        SP: { multi: 'Asociaciones', single: 'Asociación' },
        KOR: { multi: '연결', single: '협회' },
        CIN: { multi: '协会', single: '协会' },
        POL: { multi: 'Stowarzyszenia', single: 'Stowarzyszenie' },
        RUS: { multi: 'Ассоциации', single: 'Ассоциация' },
      }[lang];
    case 'ambassador':
    case 'weserve':
    case 'kiwanis':
    case 'kiwanisnl':
      return {
        GER: { multi: 'Clubs', single: 'Club' },
        ENG: { multi: 'Clubs', single: 'Club' },
        FRA: { multi: 'Club', single: 'Club' },
        HNG: { multi: 'Klub', single: 'Klub' },
        ITA: { multi: 'Club', single: 'Club' },
        JAP: { multi: 'クラブ', single: 'クラブ' },
        SP: { multi: 'Clubes', single: 'Club' },
        KOR: { multi: '클럽', single: '클럽' },
        CIN: { multi: '俱乐部', single: '俱乐部' },
        POL: { multi: 'Kluby', single: 'Klub' },
        RUS: { multi: 'Клубы', single: 'Клуб' },
      }[lang];
    case 'keb':
    case 'meinp3':
    case 'badw':
    case 'antonius':
      return {
        GER: { multi: 'Einrichtungen', single: 'Einrichtung' },
        ENG: { multi: 'Establishments', single: 'Establishment' },
        FRA: { multi: 'Installation', single: 'Installation' },
        HNG: { multi: 'Intézmény', single: 'Intézmény' },
        ITA: { multi: 'Istituzione', single: 'Istituzione' },
        JAP: { multi: '設備', single: '家具' },
        SP: { multi: 'Instalaciones', single: 'Mobiliario' },
        KOR: { multi: '시설', single: '가구' },
        CIN: { multi: '设施', single: '家具' },
        POL: { multi: 'Udogodnienia', single: 'Umeblowanie' },
        RUS: { multi: 'Объекты', single: 'Объект' },
      }[lang];
    case 'atlas':
      return {
        GER: { multi: 'Sektionen', single: 'Sektion' },
        ENG: { multi: 'Sections', single: 'Section' },
        FRA: { multi: 'Section', single: 'Section' },
        HNG: { multi: 'Szekció', single: 'Szekció' },
        ITA: { multi: 'Sezione', single: 'Sezione' },
        JAP: { multi: 'セクション', single: 'セクション' },
        SP: { multi: 'Secciones', single: 'Sección' },
        KOR: { multi: '섹션', single: '섹션' },
        CIN: { multi: '章节', single: '部门' },
        POL: { multi: 'Sekcje', single: 'Sekcja' },
        RUS: { multi: 'Секции', single: 'Секция' },
      }[lang];
    case 'co3':
      return {
        GER: { multi: 'Bünde/VACC', single: 'Bund/VACC' },
        ENG: { multi: 'Bünde/VACC', single: 'Bund/VACC' },
        FRA: { multi: 'Confédération/VACC', single: 'Confédération/VACC' },
        HNG: { multi: 'Szövetség/VACC', single: 'Szövetség/VACC' },
        ITA: { multi: 'Confederazione/VACC', single: 'Confederazione/VACC' },
        JAP: { multi: 'フレット／VACC', single: 'フレット／VACC' },
        SP: { multi: 'Trastes/VACC', single: 'Confederación/VACC' },
        KOR: { multi: '프렛/VACC', single: '연맹/VACC' },
        CIN: { multi: '捆绑/VACC', single: '邦联/瓦加杜古委员会' },
        POL: { multi: 'Fretki/VACC', single: 'Konfederacja/VACC' },
        RUS: { multi: 'Комплекты/VACC', single: 'Комплект/VACC' },
      }[lang];
    case 'asbh':
    case 'bdvb':
    case 'gdl':
    case 'kasconnect':
    case 'limconnect':
    case 'yoga':
    case 'uhh':
    case 'cbs':
    case 'epoch':
    case 'gga':
    case 'cfa':
    case 'jmg':
    case 'hda':
    case 'mofa':
    case 'mlu':
      return {
        GER: { multi: 'Gruppen', single: 'Gruppen' },
        ENG: { multi: 'Groups', single: 'Groups' },
        FRA: { multi: 'Groupes', single: 'Groupes' },
        HNG: { multi: 'Csoportok', single: 'Csoportok' },
        ITA: { multi: 'Gruppi', single: 'Gruppi' },
        JAP: { multi: 'グループ', single: 'グループ' },
        SP: { multi: 'Grupos', single: 'Grupos' },
        KOR: { multi: '그룹', single: '그룹' },
        CIN: { multi: '组别', single: '组别' },
        POL: { multi: 'Grupy', single: 'Grupy' },
        RUS: { multi: 'Группы', single: 'Группы' },
      }[lang];
    case 'kulinaristen':
      return {
        GER: { mutli: 'Stammtische', single: 'Stammtisch' },
        ENG: { mutli: 'Regulars tables', single: 'Regulars table' },
        FRA: { mutli: 'Table des habitués', single: 'Table des habitués' },
        HNG: { mutli: 'Törzsasztal', single: 'Törzsasztal' },
        ITA: { mutli: 'Tavolo dei clienti abituali', single: 'Tavolo dei clienti abituali' },
        JAP: { mutli: '常連客のテーブル', single: 'レギュラー席' },
        SP: { mutli: 'Mesas de los habituales', single: 'Mesa de los habituales' },
        KOR: { mutli: '일반인 테이블', single: '일반인 테이블' },
        CIN: { mutli: '常客桌', single: '常客桌' },
        POL: { mutli: 'Stoliki dla stałych bywalców', single: 'Tabela stałych klientów' },
        RUS: { mutli: 'Группы', single: 'Группы' },
      }[lang];
    case 'landfrauen':
      return {
        GER: { multi: 'Ortsvereine', single: 'Ortsverein' },
        ENG: { multi: 'Local associations', single: 'Local association' },
        FRA: { multi: 'Association locale', single: 'Association locale' },
        HNG: { multi: 'Helyi szövetség', single: 'Helyi szövetség' },
        ITA: { multi: 'Associazione locale', single: 'Associazione locale' },
        JAP: { multi: '地元クラブ', single: '地元協会' },
        SP: { multi: 'Clubes locales', single: 'Asociación local' },
        KOR: { multi: '지역 클럽', single: '지역 협회' },
        CIN: { multi: '当地俱乐部', single: '地方协会' },
        POL: { multi: 'Lokalne kluby', single: 'Lokalne stowarzyszenie' },
        RUS: { multi: 'Местные клубы', single: 'Местный клуб' },
      }[lang];
    case 'obotritia':
    case 'saxonia':
    case 'wurtt':
      return {
        GER: { multi: 'Bünde', single: 'Bund' },
        ENG: { multi: 'Bünde', single: 'Bund' },
        FRA: { multi: 'Confédération', single: 'Confédération' },
        HNG: { multi: 'Szövetség', single: 'Szövetség' },
        ITA: { multi: 'Confederazione', single: 'Confederazione' },
        JAP: { multi: 'フレット', single: '規約' },
        SP: { multi: 'Trastes', single: 'Pacto' },
        KOR: { multi: '프렛', single: '언약' },
        CIN: { multi: '音阶', single: '公约' },
        POL: { multi: 'Progi', single: 'Przymierze' },
        RUS: { multi: 'Фретки', single: 'Bund' },
      }[lang];
    case 'albertina':
    case 'corpsconnect':
    case 'csb':
    case 'thuringia':
    case 'rhenania':
      return {
        GER: { multi: 'Corps', single: 'Corps' },
        ENG: { multi: 'Corps', single: 'Corps' },
        FRA: { multi: 'Corps', single: 'Corps' },
        HNG: { multi: 'Testület', single: 'Testület' },
        ITA: { multi: 'Corpo', single: 'Corpo' },
        JAP: { multi: '軍団', single: '軍団' },
        SP: { multi: 'Cuerpo', single: 'Cuerpo' },
        KOR: { multi: '군단', single: '군단' },
        CIN: { multi: '军团', single: '军团' },
        POL: { multi: 'Korpus', single: 'Korpus' },
        RUS: { multi: 'Корпус', single: 'Корпус' },
      }[lang];
    case 'metallverband':
      return {
        GER: { multi: 'Innungen', single: 'Innungen' },
        ENG: { multi: 'Guilds', single: 'Guilds' },
        FRA: { multi: 'Injonctions', single: 'Injonctions' },
        HNG: { multi: 'Céhek', single: 'Céhek' },
        ITA: { multi: 'Gilde', single: 'Gilde' },
        JAP: { multi: 'ギルド', single: 'ギルド' },
        SP: { multi: 'Gremios', single: 'Gremios' },
        KOR: { multi: '길드', single: '길드' },
        CIN: { multi: '行会', single: '行会' },
        POL: { multi: 'Gildie', single: 'Gildie' },
        RUS: { multi: 'Гильдии', single: 'Гильдия' },
      }[lang];
    case 'soroptimist':
    case 'soroptimistat':
      return {
        GER: { multi: 'Mitgliedschaft', single: 'Mitgliedschaft' },
        ENG: { multi: 'Membership', single: 'Membership' },
        FRA: { multi: 'Adhésion', single: 'Adhésion' },
        HNG: { multi: 'Tagság', single: 'Tagság' },
        ITA: { multi: 'Soci', single: 'Soci' },
        JAP: { multi: 'メンバーシップ', single: 'メンバーシップ' },
        SP: { multi: 'Afiliación', single: 'Afiliación' },
        KOR: { multi: '멤버십', single: '멤버십' },
        CIN: { multi: '会员资格', single: '会员资格' },
        POL: { multi: 'Członkostwo', single: 'Członkostwo' },
        RUS: { multi: 'Членство', single: 'Членство' },
      }[lang];
    case 'awo':
    case 'bayern':
    case 'vbrk':
    case 'werteunion':
    case 'dphv':
    case 'dbfk':
    case 'bsb':
    case 'rlp':
    case 'reservis':
    case 'bawu':
    case 'bsm':
    case 'nrw':
    case 'mpg':
    case 'rhlv':
    case 'dkfz':
    case 'eh-darmstadt':
    case 'teutonia':
    case 'lfv-rhein-lahn':
    case 'kab':
    case 'muettergenesungswerk':
    case 'dve':
    case 'ljn':
    case 'bdp':
      return {
        GER: { multi: 'Gliederungen', single: 'Gliederungen' },
        ENG: { multi: 'Gliederungen', single: 'Gliederungen' },
        FRA: { multi: 'Gliederungen', single: 'Gliederungen' },
        HNG: { multi: 'Gliederungen', single: 'Gliederungen' },
        ITA: { multi: 'Gliederungen', single: 'Gliederungen' },
        JAP: { multi: '構造', single: '構造' },
        SP: { multi: 'Estructuras', single: 'Estructuras' },
        KOR: { multi: '구조', single: '구조' },
        CIN: { multi: '结构', single: '结构' },
        POL: { multi: 'Struktury', single: 'Struktury' },
        RUS: { multi: 'Структуры', single: 'Структура' },
      }[lang];
    case 'aps':
      return {
        GER: { multi: 'Organisationen & AGs', single: 'Organisationen & AGs' },
        ENG: { multi: 'Organization & AGs', single: 'Organization & AGs' },
        FRA: { multi: 'Organisationen & AGs', single: 'Organisationen & AGs' },
        HNG: { multi: 'Organisationen & AGs', single: 'Organisationen & AGs' },
        ITA: { multi: 'Organisationen & AGs', single: 'Organisationen & AGs' },
        JAP: { multi: '組織とAG', single: '組織とAG' },
        SP: { multi: 'Organizaciones y AG', single: 'Organizaciones y AG' },
        KOR: { multi: '조직 및 AG', single: '조직 및 AG' },
        CIN: { multi: '组织和 AG', single: '组织和 AG' },
        POL: { multi: 'Organizacje i AG', single: 'Organizacje i AG' },
        RUS: { multi: 'Организации и AG', single: 'Организация и AG' },
      }[lang];
    case 'wilkar':
      return {
        GER: { multi: 'Objekte', single: 'Objekte' },
        ENG: { multi: 'Objekte', single: 'Objekte' },
        FRA: { multi: 'Objekte', single: 'Objekte' },
        HNG: { multi: 'Objekte', single: 'Objekte' },
        ITA: { multi: 'Objekte', single: 'Objekte' },
        JAP: { multi: '対象物', single: '対象物' },
        SP: { multi: 'Objetos', single: 'Objetos' },
        KOR: { multi: '개체', single: '개체' },
        CIN: { multi: '对象', single: '对象' },
        POL: { multi: 'Obiekty', single: 'Obiekty' },
        RUS: { multi: 'Объекты', single: 'Объект' },
      }[lang];
    case 'eventus':
      return {
        GER: { multi: 'Projekte', single: 'Projekte' },
        ENG: { multi: 'Projekte', single: 'Projekte' },
        FRA: { multi: 'Projekte', single: 'Projekte' },
        HNG: { multi: 'Projekte', single: 'Projekte' },
        ITA: { multi: 'Projekte', single: 'Projekte' },
        JAP: { multi: 'プロジェクト', single: 'プロジェクト' },
        SP: { multi: 'Proyectos', single: 'Proyectos' },
        KOR: { multi: '프로젝트', single: '프로젝트' },
        CIN: { multi: '项目', single: '项目' },
        POL: { multi: 'Projekty', single: 'Projekty' },
        RUS: { multi: 'Проекты', single: 'Проект' },
      }[lang];
    case 'unisono':
      return {
        GER: { multi: 'Klangkörper', single: 'Klangkörper' },
        ENG: { multi: 'Klangkörper', single: 'Klangkörper' },
        FRA: { multi: 'Klangkörper', single: 'Klangkörper' },
        HNG: { multi: 'Klangkörper', single: 'Klangkörper' },
        ITA: { multi: 'Klangkörper', single: 'Klangkörper' },
        JAP: { multi: 'サウンドボディ', single: 'サウンドボディ' },
        SP: { multi: 'Cuerpo sano', single: 'Cuerpo sano' },
        KOR: { multi: '사운드 바디', single: '사운드 바디' },
        CIN: { multi: '健全的身体', single: '健全的身体' },
        POL: { multi: 'Ciało dźwiękowe', single: 'Ciało dźwiękowe' },
        RUS: { multi: 'Звучное тело', single: 'Звучное тело' },
      }[lang];
    case 'ihk':
    case 'ihkfrankfurt':
      return {
        GER: { multi: 'Bereiche', single: 'Bereiche' },
        ENG: { multi: 'Bereiche', single: 'Bereiche' },
        FRA: { multi: 'Bereiche', single: 'Bereiche' },
        HNG: { multi: 'Bereiche', single: 'Bereiche' },
        ITA: { multi: 'Bereiche', single: 'Bereiche' },
        JAP: { multi: '地域', single: '地域' },
        SP: { multi: 'Zonas', single: 'Zonas' },
        KOR: { multi: '영역', single: '영역' },
        CIN: { multi: '地区', single: '地区' },
        POL: { multi: 'Obszary', single: 'Obszary' },
        RUS: { multi: 'Области', single: 'Область' },
      }[lang];
    case 'connact':
      return {
        GER: { multi: 'Gruppe', single: 'Gruppe' },
        ENG: { multi: 'Group', single: 'Groups' },
        FRA: { multi: 'Groupe', single: 'Groupes' },
        HNG: { multi: 'Csoport', single: 'Csoportok' },
        ITA: { multi: 'Gruppo', single: 'Gruppi' },
        JAP: { multi: 'グループ', single: 'グループ' },
        SP: { multi: 'Grupo', single: 'Grupo' },
        KOR: { multi: '그룹', single: '그룹' },
        CIN: { multi: '组别', single: '组别' },
        POL: { multi: 'Grupa', single: 'Grupa' },
        RUS: { multi: 'Группа', single: 'Группа' },
      }[lang];
    case 'dbv':
      return {
        GER: { multi: 'Regionalverbände', single: 'Regionalverbände' },
        ENG: { multi: 'Regional associations', single: 'Regional associations' },
        FRA: { multi: 'Regional associations', single: 'Regional associations' },
        HNG: { multi: 'Regional associations', single: 'Regional associations' },
        ITA: { multi: 'Regional associations', single: 'Regional associations' },
        JAP: { multi: '構造', single: '構造' },
        SP: { multi: 'Estructuras', single: 'Estructuras' },
        KOR: { multi: '구조', single: '구조' },
        CIN: { multi: '结构', single: '结构' },
        POL: { multi: 'Struktury', single: 'Struktury' },
        RUS: { multi: 'Структуры', single: 'Структура' },
      }[lang];
    case 'lgf':
      return {
        GER: { multi: 'Kurse', single: 'Kurse' },
        ENG: { multi: 'Courses', single: 'Courses' },
        FRA: { multi: 'Courses', single: 'Courses' },
        HNG: { multi: 'Courses', single: 'Courses' },
        ITA: { multi: 'Courses', single: 'Courses' },
        JAP: { multi: '構造', single: '構造' },
        SP: { multi: 'Estructuras', single: 'Estructuras' },
        KOR: { multi: '구조', single: '구조' },
        CIN: { multi: '结构', single: '结构' },
        POL: { multi: 'Struktury', single: 'Struktury' },
        RUS: { multi: 'Структуры', single: 'Структура' },
      }[lang];
    case 'sherides':
      return {
        GER: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        ENG: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        FRA: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        HNG: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        ITA: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        JAP: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        SP: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        KOR: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        CIN: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        POL: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
        RUS: { multi: 'Ortsgruppen', single: 'Ortsgruppen' },
      }[lang];
    default:
      return {
        GER: { multi: 'Korporationen', single: 'Korporation' },
        ENG: { multi: 'Korporations', single: 'Korporation' },
        FRA: { multi: 'Korporation', single: 'Korporation' },
        HNG: { multi: 'Korporáció', single: 'Korporáció' },
        ITA: { multi: 'Corporazione', single: 'Corporazione' },
        JAP: { multi: '企業', single: 'コーポレーション' },
        SP: { multi: 'Empresas', single: 'Corporación' },
        KOR: { multi: '기업', single: '법인' },
        CIN: { multi: '公司', single: '公司' },
        POL: { multi: 'Korporacje', single: 'Korporacja' },
        RUS: { multi: 'Корпорации', single: 'Корпорация' },
      }[lang];
  }
};

// shoundt be used on registration screen
export const CountryName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'wilkar':
      return {
        GER: 'Herkunftsland',
        ENG: 'Country of origin',
        ITA: 'Paese di origine',
        FRA: 'Pays de provenance',
        HNG: 'Származási ország',
        JAP: '原産国',
        SP: 'País de origen',
        KOR: '원산지 국가',
        CIN: '原籍国',
        POL: 'Kraj pochodzenia',
        RUS: 'Страна происхождения',
      }[lang];
    default:
      return {
        GER: 'Land',
        ENG: 'Country',
        ITA: 'Nazione',
        FRA: 'Pays',
        HNG: 'Ország',
        JAP: '国名',
        SP: 'País',
        KOR: '국가',
        CIN: '国家',
        POL: 'Kraj',
        RUS: 'Страна',
      }[lang];
  }
};

export const CompanyName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'dphv':
      return {
        GER: 'Schule',
        ENG: 'School',
        ITA: 'Scuola',
        FRA: 'École',
        HNG: 'Iskola',
        JAP: '学校',
        SP: 'Escuela',
        KOR: '학교',
        CIN: '学校',
        POL: 'Szkoła',
        RUS: 'Школа',
      }[lang];
    default:
      return {
        GER: 'Unternehmen',
        ENG: 'Company',
        ITA: 'Aziende',
        FRA: 'Société',
        HNG: 'Cég',
        JAP: '同社',
        SP: 'La empresa',
        KOR: '회사',
        CIN: '该公司',
        POL: 'Firma',
        RUS: 'Компания',
      }[lang];
  }
};

export const IndustryName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'dphv':
      return {
        GER: 'Schulform',
        ENG: 'Type of school',
        ITA: 'Tipo di scuola',
        FRA: "Type d'école",
        HNG: 'Az iskola típusa',
        SP: 'Tipo de escuela',
        KOR: '학교 유형',
        JAP: '学校の種類',
        CIN: '学校类型',
        POL: 'Typ szkoły',
        RUS: 'Тип школы',
      }[lang];
    default:
      return {
        GER: 'Branche',
        ENG: 'Industry',
        ITA: 'Ramo',
        FRA: 'Industrie',
        HNG: 'Ipar',
        SP: 'Industria',
        KOR: '산업',
        JAP: '産業',
        CIN: '行业',
        POL: 'Przemysł',
        RUS: 'Отрасль',
      }[lang];
  }
};

export const ActivityName = (lang: langTypes) => {
  switch (config.APP_NAME) {
    case 'dphv':
      return {
        GER: 'Fächer',
        ENG: 'Subjects',
        ITA: 'Soggetti',
        FRA: 'Éventail',
        HNG: 'Tantárgyak',
        SP: 'Temas',
        KOR: '주제',
        JAP: '対象',
        CIN: '主题',
        POL: 'Przedmioty',
        RUS: 'Предметы',
      }[lang];
    default:
      return {
        GER: 'Ihr Tätigkeitsfeld',
        ENG: 'Field of activity',
        ITA: 'Il tuo campo di attività',
        FRA: "Votre domaine d'activité",
        HNG: 'Tevékenységi Szakterület',
        SP: 'Ámbito de actividad',
        KOR: '활동 분야',
        JAP: '活動分野',
        CIN: '活动领域',
        POL: 'Obszar działalności',
        RUS: 'Область деятельности',
      }[lang];
  }
};
