import React, { useEffect, useState } from 'react';
import { attachmentsType } from './components/MessageAttachments';
import { getS3Link } from 'utilities/setS3Prefix';

export const useAttachmentsUrls = (attachments) => {
  const [fixed, setFixed] = useState({} as attachmentsType);

  useEffect(() => {
    fixLinks();
  }, []);

  const fixLinks = () => {
    const newObj = {} as attachmentsType;

    for (const key in attachments)
      if (attachments?.[key]?.length) {
        newObj[key] = [];

        attachments[key].map((file) => {
          if (!file.url.includes('http')) {
            const extendedFile = {
              ...file,
              url: getS3Link(file.url),
              thumb: getS3Link(file.thumb),
            };
            return newObj[key].push(extendedFile);
          }
          newObj[key].push(file);
        });
      }

    setFixed(newObj);
  };
  return fixed;
};
