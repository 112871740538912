import React, { FC, useState, useEffect } from 'react';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { mediaActions, selectMediaState } from 'store/reducers/mediaSlice';
import * as api from 'api/documents';

import Layout from 'components/UI/Layout';
import ScreenTitle from 'components/Shared/ScreenTitle';
import { Box, ButtonBase } from '@material-ui/core';
import { FolderItem } from './components/FolderItem';
import DocumentsGalleryItem from './components/DocumentsGalleryItem';
import { CurrentFolder } from './components/CurrentFolder';
import Loader from 'components/UI/Loader';
import { FolderModal } from './components/FolderModal';
import { RenderStateAttachments } from 'scenes/DialogPanel/components/RenderStateAttachments';
import { UploadMedia } from 'components/Shared/UploadMedia';
import DefaultModal from 'components/Shared/DefaultModal';
import { useHistory } from 'react-router';
import { useFilesCookies } from 'hooks/useFilesCookies';
import { ScrollToTopButton } from 'components/UI/ScrollToTop/ScrollToTopButton';
import { selectCommonState } from 'store/reducers/common';

type pathType = {
  _id: string;
  title: string;
  prevFolder?: { _id: string; title: string; scrollPosY: number };
  scrollPosY: number;
};

export const DocumentsScreen: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { allow_doc_deletion } = useAppSelector(selectCommonState);

  const { videos, images, documents, loadingCounter, loading } = useAppSelector(selectMediaState);
  const attachments = { videos, images, documents };
  const hasMedia = !!(documents.length || images.length || videos.length || loadingCounter > 0);
  const { drawer } = useLanguage();
  // const [documents, setDocuments] = useState([] as any[])
  const [showLoader, setShowLoader] = useState(false);
  const [folderModal, setFolderModal] = useState<folderModalType>({ show: false, mode: null });
  const [folders, setFolders] = useState({} as folderType);
  const { id, title } = history.location.state?.preopenedFolder || {};
  const currentPathDefalut = {
    _id: id || '',
    title: title || 'Root',
    prevFolder: id?.length
      ? { _id: '', title: 'Root', scrollPosY: 0 }
      : (null as { _id: string; title: string; scrollPosY: number }),
    scrollPosY: 0,
  };
  const [currentPath, setCurrentPath] = useState<pathType>(currentPathDefalut);
  const [dropdownState, setDropdownState] = useState({
    show: false,
    id: '',
    title: '',
  });
  const user = useAppSelector((state) => state.users.user);
  useFilesCookies(user.cookie);

  useEffect(() => {
    history.replace({ ...history.listen, state: {} });
  }, []);

  useEffect(() => {
    if (currentPath.title !== 'Root') getFolder(currentPath._id);
    if (currentPath.title === 'Root') getRootFolder();
  }, [currentPath]);

  const getRootFolder = async () => {
    setShowLoader(true);
    const res = await api.getRootFolder();
    setFolders(res);
    setShowLoader(false);
  };

  const getFolder = async (id: string) => {
    setShowLoader(true);
    const res = await api.getFolderById(id);
    setFolders(res);
    setShowLoader(false);
  };

  const navigateToFolder = (_id: string, title: string) => {
    setShowLoader(true);
    setCurrentPath({
      _id,
      title,
      scrollPosY: 0,
      prevFolder: { ...currentPath, scrollPosY: window.scrollY },
    });
    setShowLoader(false);
  };

  const goToParentFolder = () => {
    setShowLoader(true);
    if (!currentPath.prevFolder?._id?.length) {
      setCurrentPath({ _id: '', title: 'Root', scrollPosY: 0 });
    }
    setCurrentPath({ ...currentPath.prevFolder });
    setShowLoader(false);
    setTimeout(() => {
      window.scrollTo({
        top: currentPath.prevFolder.scrollPosY,
      });
    }, 300);
  };

  const createNewFolder = async (newTitle: string) => {
    setShowLoader(true);
    const res: any = await api.createFolder(currentPath._id, newTitle);
    const { _id, title, creator } = res;
    setFolders({ ...folders, folders: [...folders.folders, { _id, title, creator }] });
    setShowLoader(false);
    setFolderModal({ show: false, mode: null });
  };

  const deleteFolder = async (id: string) => {
    setShowLoader(true);
    await api.deleteFolder(id);
    setFolders({ ...folders, folders: folders.folders?.filter((el) => el._id !== id) });
    setShowLoader(false);
  };

  const renameFolder = async (id: string, title: string) => {
    setShowLoader(true);
    await api.renameFolder(id, title);
    const idx = folders.folders?.findIndex((el) => el._id === id);
    if (idx !== -1) {
      const newFolders = folders.folders;
      newFolders[idx].title = title;
      setFolders({ ...folders, folders: newFolders });
    }
    setShowLoader(false);
    setFolderModal({ show: false, mode: null });
  };

  const renameFile = async (id: string, title: string) => {
    setShowLoader(true);
    await api.renameDocument(id, title);
    const idx = folders.files?.findIndex((el) => el._id === id);
    if (idx !== -1) {
      const newFiles = folders.files;
      newFiles[idx].filename = title;
      setFolders({ ...folders, files: newFiles });
    }
    setShowLoader(false);
    setFolderModal({ show: false, mode: null });
  };

  const getFullPath = () => {
    let a = [currentPath._id];
    const recursion = (nextVal) => {
      if (nextVal.prevFolder?.id) {
        a = [nextVal.prevFolder.id, ...a];
        recursion(nextVal.prevFolder);
      }
      return a;
    };
    const fullPath = recursion(currentPath);
    console.log('fullPath', fullPath);
    return fullPath;
  };

  const handleDeleteDocument = (id: string) => {
    setFolders({ ...folders, files: folders.files?.filter((el) => el._id !== id) });
  };

  const handleUploadDocuments = (newDocuments: Array<any>) => {
    setFolders({ ...folders, files: [...newDocuments, ...folders.files] });
    setShowLoader(false);
  };

  const sendFiles = async () => {
    setShowLoader(true);
    const pathToUpload = currentPath._id ? { folder: currentPath._id } : {};
    let files = {
      type: 'mobile',
      documents: [...attachments.documents, ...attachments.images, ...attachments.videos],
      awsUpload: true,
      ...pathToUpload,
    };

    const uploadResponse: any = await api.uploadDocuments(files);
    setShowLoader(false);
    dispatch(mediaActions.deleteAllAttachments());
    handleUploadDocuments(uploadResponse);
  };

  const onClose = () => {
    dispatch(mediaActions.deleteAllAttachments());
    setShowLoader(false);
  };

  return (
    <Layout>
      <ScreenTitle title={drawer.documents} uploadMedia />
      <CurrentFolder
        currentPath={currentPath}
        goToParentFolder={goToParentFolder}
        showFoldersModal={() => setFolderModal({ show: true, mode: 'createFolder' })}
      />
      <Box style={{ padding: 20 }}>
        {folders?.folders?.map((folder) => (
          <FolderItem
            title={folder.title}
            id={folder._id}
            isPrivate={folder.isPrivate}
            key={folder._id}
            navigateToFolder={navigateToFolder}
            deleteFolder={deleteFolder}
            showDeleteButton={allow_doc_deletion}
            showFolderModal={(id: string, title: string) => {
              setFolderModal({ show: true, mode: 'renameFolder' });
              setDropdownState({ id, title, show: false });
            }}
            owner={user?._id === folder?.creator}
          />
        ))}
        {folders?.files?.map(
          (doc) =>
            doc.filename && (
              <DocumentsGalleryItem
                key={doc._id}
                document={doc}
                handleDeleteDocument={handleDeleteDocument}
                showDeleteButton={allow_doc_deletion}
                showFolderModal={(id: string, title: string) => {
                  setFolderModal({ show: true, mode: 'renameFile' });
                  setDropdownState({ id, title, show: false });
                }}
                setShowLoading={setShowLoader}
                owner={user?._id === doc?.uploader?._id}
              />
            ),
        )}
        {hasMedia && (
          <DefaultModal
            showModal={hasMedia}
            onClose={() => {
              dispatch(mediaActions.deleteAllAttachments());
            }}
            modalTitle={'Dateien ausgewählt:'}
          >
            <Box style={{ position: 'absolute', top: 10, right: 10, zIndex: 2 }}>
              <UploadMedia slideToBottom allFormats isPrivate viewType="button" />
            </Box>
            <Box
              style={{
                maxHeight: 500,
                padding: '20px 40px',
                overflowY: 'auto',
              }}
            >
              <RenderStateAttachments
                deleteAttachment={(type, id) => {
                  dispatch(mediaActions.deleteAttachment({ type, id }));
                }}
                currentAttachments={attachments}
                viewType="row"
              />
            </Box>
            <Box style={styles.buttonWrapper}>
              <ButtonBase onClick={onClose} style={styles.button}>
                <span style={styles.buttonText}>Abbrechen</span>
              </ButtonBase>
              <ButtonBase
                onClick={sendFiles}
                style={{
                  ...styles.button,
                  backgroundColor: loading ? '#ccc' : theme.BUTTON_PRIMARY,
                }}
                disabled={loading}
              >
                <span style={styles.buttonText}>Daten hochladen</span>
              </ButtonBase>
            </Box>
          </DefaultModal>
        )}
        <FolderModal
          showModal={folderModal.show}
          mode={folderModal.mode}
          modalHandler={() => {
            setFolderModal({ show: false, mode: null });
          }}
          createNewFolder={createNewFolder}
          renameFolder={renameFolder}
          renameFile={renameFile}
          title={dropdownState.title}
          id={dropdownState.id}
        />
        <Loader showLoader={showLoader} />
      </Box>
      <ScrollToTopButton />
    </Layout>
  );
};

const { config, theme } = getConfig();
const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: 20,
    marginRight: 20,
    backgroundColor: theme.BUTTON_PRIMARY,
    // padding: '15px 6px',
    padding: 6,
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
  },
};

export type folderType = {
  title: string;
  _id: string;
  folders?: { title: string; _id: string; isPrivate?: boolean | null; creator: string }[];
  files?: fileType[];
};

type fileType = {
  __v: number;
  _id: string;
  createdAt: string;
  filename: string;
  mimetype: string;
  path: string;
  updatedAt: string;
  uploader?: any;
};

export type folderModalType = { show: boolean; mode: 'createFolder' | 'renameFolder' | 'renameFile' | null };
